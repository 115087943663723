import { ApiLookupBase } from '@api/types';

export const GENDER_OPTIONS: ApiLookupBase[] = [
  { Id: 0, Name: 'Мужской' },
  { Id: 1, Name: 'Женский' },
];

export const ALIGNMENT_OPTIONS: ApiLookupBase[] = [
  { Id: 0, Name: 'Законопослушный добрый' },
  { Id: 1, Name: 'Законопослушный нейтральный' },
  { Id: 2, Name: 'Законопослушный злой' },
  { Id: 3, Name: 'Нейтральный добрый' },
  { Id: 4, Name: 'Истинно нейтральный' },
  { Id: 5, Name: 'Нейтральный злой' },
  { Id: 6, Name: 'Хаотичный добрый' },
  { Id: 7, Name: 'Хаотичный нейтральный' },
];
