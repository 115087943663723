import { Injectable } from '@angular/core';
import { finalize, Observable, tap } from 'rxjs';

import { AppArraySource } from '@core/types';
import { LoaderSubject } from '@core/helpers';

@Injectable()
export abstract class ArraySourceService<T> extends AppArraySource<T> {
  loader$ = new LoaderSubject();

  constructor() {
    super();
  }

  loadData() {
    this.loader$.loading('loadData');
    return this.request().pipe(
      finalize(() => this.loader$.loaded('loadData')),
      tap(data => this.setData(data))
    );
  }

  protected abstract request(): Observable<T[]>;
}
