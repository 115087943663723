import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';

import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor(@Optional() @Inject(DOCUMENT) private document: Document, private platform: PlatformService) {}

  setItem(key: string, value: string, days: number) {
    if (this.platform.isServer || !this.document) return;
    const d = new Date();
    d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
    const host = this.document.location.hostname;
    this.document.cookie = `${key}=${value};expires=${d.toUTCString()};path=/;domain=${host}`;
  }

  getItem(key: string) {
    return this.document.cookie
      .split('; ')
      .find(item => item.split('=')[0] === key)
      ?.split('=')[1];
  }

  removeItem(key: string) {
    this.setItem(key, '', 0);
  }
}
