import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

export class LoaderSubject extends BehaviorSubject<string[]> {
  constructor() {
    super([]);
  }

  loading(id: string) {
    this.getValue().push(id);
    this.next(this.getValue());
  }

  loaded(id: string) {
    this.next(this.getValue().filter(item => item !== id));
  }

  get(id: string) {
    return this.pipe(map(data => !!data.find(item => item === id)));
  }

  getList(ids: string[]) {
    return this.pipe(map(data => !!data.find(item => ids.includes(item))));
  }

  get loading$() {
    return this.pipe(
      map(data => !!data.length),
      distinctUntilChanged()
    );
  }

  get loaded$() {
    return this.loading$.pipe(map(data => !data));
  }

  isLoading(): boolean {
    return !!this.getValue().length;
  }
}
