import { DOCUMENT } from '@angular/common';
import { afterNextRender, Inject, Injectable } from '@angular/core';

import { StorageData, StorageDataKeys } from '@core/types';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private storage?: Storage = undefined;

  constructor(@Inject(DOCUMENT) private document: Document) {
    afterNextRender(() => (this.storage = this.document.defaultView?.localStorage));
  }

  setItem<T extends StorageDataKeys>(key: T, data: StorageData[T]) {
    return this.storage ? this.storage.setItem(key, JSON.stringify(data)) : null;
  }

  getItem<T extends StorageDataKeys>(key: T) {
    return this.storage ? (JSON.parse(<string>this.storage.getItem(key)) as StorageData[T] | null) : null;
  }

  removeItem<T extends StorageDataKeys>(key: T) {
    this.storage?.removeItem(key);
  }

  clear() {
    this.storage?.clear();
    localStorage.clear();
  }
}
