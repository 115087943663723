import { Injectable, TemplateRef } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class ToastrService {
  constructor(private snackBar: MatSnackBar) {}

  show(message: string, config = { duration: 3000 }) {
    return this.snackBar.open(message, '', config);
  }

  showFromTempalate(template: TemplateRef<unknown>, config: MatSnackBarConfig = { duration: 3000 }) {
    return this.snackBar.openFromTemplate(template, config);
  }
}
