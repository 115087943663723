import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';

const MOBILE = '(max-width: 991px)';
const TABLET = '(max-width: 1199px)';

@Injectable({
  providedIn: 'root',
})
export class ScreenService {
  private isMobileSource$ = new BehaviorSubject<boolean>(this.breakpointObserver.isMatched(MOBILE));
  isMobile$ = this.isMobileSource$.asObservable();
  private setIsMobile(value: boolean) {
    this.isMobileSource$.next(value);
  }
  isMobile() {
    return this.isMobileSource$.getValue();
  }

  private isTabletSource$ = new BehaviorSubject<boolean>(this.breakpointObserver.isMatched(TABLET));
  isTablet$ = this.isTabletSource$.asObservable();
  private setIsTablet(value: boolean) {
    this.isTabletSource$.next(value);
  }
  isTablet() {
    return this.isTabletSource$.getValue();
  }

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver.observe([MOBILE]).subscribe(result => this.setIsMobile(result.matches));
    this.breakpointObserver.observe([TABLET]).subscribe(result => this.setIsTablet(result.matches));
  }
}
