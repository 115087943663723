import { BehaviorSubject, map } from 'rxjs';

export class AppArraySource<T> {
  private dataSource$ = new BehaviorSubject<T[]>([]);
  data$ = this.dataSource$.asObservable();

  private listSource$ = new BehaviorSubject<T[]>([]);
  list$ = this.listSource$.asObservable();
  setList(data: T[]) {
    this.listSource$.next(data);
  }
  getList() {
    return this.listSource$.getValue();
  }

  total$ = this.data$.pipe(map(data => data.length));
  filteredTotal$ = this.list$.pipe(map(data => data.length));

  constructor(data?: T[]) {
    if (data) this.setData(data);
  }

  setData(data: T[]) {
    this.dataSource$.next(data);
  }

  getData() {
    return this.dataSource$.getValue();
  }
}
