import { environment } from 'src/environments/environment';

export class AppConstants {
  static URL = environment.URL;
  static API_URL = environment.API_URL;
  static YA_METRIKA_ID = environment.YA_METRIKA_ID;
  static LOCAL = environment.LOCAL;
  static DEV = environment.DEV;
  static PROD = environment.PROD;
  static readonly RELEASE_VERSION = environment.RELEASE?.VERSION;
  static readonly RELEASE_DATE = environment.RELEASE?.DATE ? new Date(environment.RELEASE?.DATE) : null;

  static MAT_RIPPLE_COLOR = '#f33b0360';
  static DEFAULT_DESCRIPTION =
    'D&D Campaign онлайн сервис: цифровой лист персонажа, ведение приключений и целых кампаний, удобный поиск заклинаний.';
}
