import { AppDictionary } from '@core/types/dictionary';

type GroupByFunction<T> = (item: T) => string;

export function GroupBy<T>(array: T[], prop: keyof T | GroupByFunction<T>) {
  const res = <AppDictionary<T[]>>{};
  if (typeof prop === 'function' || <any>prop instanceof Function) {
    return (array || ([] as T[])).reduce(
      (r, v, i, a, k = (prop as Function)(v)) => ((r[k] || (r[k] = [])).push(v), r),
      res
    );
  } else if (typeof prop === 'string' || (prop as any) instanceof String) {
    return (array || ([] as T[])).reduce(
      (r, v, i, a, k = v[prop]) => ((r[k as any] || (r[k as any] = [])).push(v), r),
      res
    );
  } else {
    return res;
  }
}
