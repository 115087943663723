import { Injectable } from '@angular/core';

import { ApiAdminService } from '@api/services';
import { ApiAdminUserListItem } from '@api/types';

import { AppArraySource } from '@core/types';

@Injectable({
  providedIn: 'root',
})
export class LookupAdminDataService {
  users = new AppArraySource<ApiAdminUserListItem>();

  constructor(private apiAdminService: ApiAdminService) {}

  loadData() {
    this.apiAdminService.getAllUsers().subscribe(data => this.users.setData(data));
  }
}
