import { ApiAuthRoleType } from '@api/types';

export interface JwtToken {
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/role': ApiAuthRoleType;
  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name': string;
  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier': Guid;
  nbf: number;
  exp: number;
  iss: 'DNDServiceServer';
  aud: 'DNDServiceClient';
}

export class JwtToken {
  static parse(token: string | undefined): JwtToken | null {
    if (!token) return null;
    try {
      let base64Url = token.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
          .join('')
      );
      return JSON.parse(jsonPayload);
    } catch {
      return null;
    }
  }
}
