import { AbstractControl, AbstractControlOptions, AsyncValidatorFn, FormArray, ValidatorFn } from '@angular/forms';
import { Subject } from 'rxjs';
import { AppFormGroup } from './form-group';

export class AppFormArray<F, V> extends FormArray<any> {
  destroy$ = new Subject<void>();

  constructor(
    controls: Array<{ [key in keyof V]: AbstractControl }>,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super(controls, validatorOrOpts, asyncValidator);
  }

  getControls() {
    return this.controls as F[];
  }

  override getRawValue() {
    return super.getRawValue() as V[];
  }

  addItem(data: F) {
    this.push(data);
  }

  removeItem(data: F) {
    this.removeAt(this.getControls().findIndex(item => item === data));
  }

  validate() {
    if (this.invalid) {
      this.updateValueAndValidity();
      this.markAllAsTouched();
    }
    return this.valid;
  }

  destroy() {
    Object.values(this.controls).forEach(item => {
      if ((item instanceof AppFormGroup || item instanceof AppFormArray) && item.destroy && !item.destroy$.closed)
        item.destroy();
    });
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
