export interface AppPluralizedIntNounState {
  zero?: string;
  one?: string;
  two?: string;
}

export const getPluralizedIntNoun = (value: number | null, state: AppPluralizedIntNounState) => {
  if (value === null) return '';
  const _absValue = Math.abs(value);
  const _simpleValue = _absValue % 10;
  return _simpleValue === 0 || (_absValue > 10 && _absValue < 20) || _simpleValue >= 5
    ? state.zero || ''
    : _simpleValue === 1
    ? state.one || ''
    : state.two || '';
};
