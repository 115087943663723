import { Pipe, PipeTransform } from '@angular/core';

import { AppPluralizedIntNounState, getPluralizedIntNoun } from './pluralize';

@Pipe({
  name: 'pluralize',
  standalone: true,
})
export class PluralizePipe implements PipeTransform {
  transform(data: number | null, state: AppPluralizedIntNounState): string {
    return getPluralizedIntNoun(data, state);
  }
}
