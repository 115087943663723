import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

interface AppSeo {
  siteName?: string;
  title?: string;
  description?: string;
  type?: 'website' | 'article';
}

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(@Inject(DOCUMENT) private document: Document, private title: Title, private meta: Meta) {}

  set(data: AppSeo) {
    if (data.siteName) this.setSiteName(data.siteName);
    this.setUrl(this.document.location.href);
    if (data.title) this.setTitle(data.title);
    if (data.description) this.setDescription(data.description);
    if (data.type) this.setType(data.type);
  }

  private setSiteName(data: string) {
    this.meta.updateTag({ property: 'og:site_name', content: data }, "property='og:site_name'");
  }

  private setUrl(data: string) {
    this.meta.updateTag({ property: 'og:url', content: data }, "property='og:url'");
  }

  private setTitle(data: string) {
    this.title.setTitle(data);
    this.meta.updateTag({ property: 'og:title', content: data }, "property='og:title'");
  }

  private setDescription(data: string) {
    this.meta.updateTag({ name: 'description', content: data });
    this.meta.updateTag({ property: 'og:description', content: data }, "property='og:description'");
  }

  private setType(data: string) {
    this.meta.updateTag({ property: 'og:type', content: data }, "property='og:type'");
  }
}
