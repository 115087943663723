import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import {
  ApiLookupLevel,
  ApiLookupSavingThrowType,
  ApiLookupClass,
  ApiLookupComponentType,
  ApiLookupEffectType,
  ApiLookupDice,
  ApiLookupRest,
  ApiLookupSpellSchoolType,
  ApiLookupRangeType,
  ApiLookupAreaType,
  ApiLookupCastingTimeType,
  ApiLookupDurationTimeType,
  ApiLookupDurationType,
  ApiLookupCopyrightHolders,
  ApiLookupTranslators,
  ApiLookupMagicItemCategoryType,
  ApiLookupMagicItemRarityType,
  ApiLookupMagicItemChargesResetType,
  ApiLookupRace,
} from '@api/types';

import { AppConstants } from '@core/app-constants';
import { getSpellLevelName } from '@core/helpers';

@Injectable({
  providedIn: 'root',
})
export class ApiLookupService {
  constructor(private http: HttpClient) {}

  getLevels() {
    return of<ApiLookupLevel[]>([0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(Id => ({ Id: Id, Name: getSpellLevelName(Id) })));
  }

  getClasses() {
    return this.http.get<ApiLookupClass[]>(`${AppConstants.API_URL}/Lookup/Classes`);
  }

  getSavingThrowTypes() {
    return this.http.get<ApiLookupSavingThrowType[]>(`${AppConstants.API_URL}/Lookup/SavingThrowTypes`);
  }

  getComponentTypes() {
    return this.http.get<ApiLookupComponentType[]>(`${AppConstants.API_URL}/Lookup/ComponentTypes`);
  }

  getEffectTypes() {
    return this.http.get<ApiLookupEffectType[]>(`${AppConstants.API_URL}/Lookup/EffectTypeTypes`);
  }

  getDiceTypes() {
    return this.http.get<ApiLookupDice[]>(`${AppConstants.API_URL}/Lookup/DiceTypes`);
  }

  getRestTypes() {
    return this.http.get<ApiLookupRest[]>(`${AppConstants.API_URL}/Lookup/RestTypes`);
  }

  getSpellSchoolTypes() {
    return this.http.get<ApiLookupSpellSchoolType[]>(`${AppConstants.API_URL}/Lookup/SpellSchoolTypes`);
  }

  getDurationType() {
    return this.http.get<ApiLookupDurationType[]>(`${AppConstants.API_URL}/Lookup/DurationTypes`);
  }

  getDurationTimeType() {
    return this.http.get<ApiLookupDurationTimeType[]>(`${AppConstants.API_URL}/Lookup/DurationTimeTypes`);
  }

  getCastingTimeType() {
    return this.http.get<ApiLookupCastingTimeType[]>(`${AppConstants.API_URL}/Lookup/CastingTimeTypes`);
  }

  getRangeType() {
    return this.http.get<ApiLookupRangeType[]>(`${AppConstants.API_URL}/Lookup/RangeTypes`);
  }

  getAreaType() {
    return this.http.get<ApiLookupAreaType[]>(`${AppConstants.API_URL}/Lookup/AreaTypes`);
  }

  getCopyrightHolders() {
    return this.http.get<ApiLookupCopyrightHolders[]>(`${AppConstants.API_URL}/Lookup/CopyrightHolders`);
  }

  getTranslators() {
    return this.http.get<ApiLookupTranslators[]>(`${AppConstants.API_URL}/Lookup/Translators`);
  }

  getMagicItemCategoryTypes() {
    return this.http.get<ApiLookupMagicItemCategoryType[]>(`${AppConstants.API_URL}/Lookup/MagicItemCategoryTypes`);
  }

  getMagicItemRarityTypes() {
    return this.http.get<ApiLookupMagicItemRarityType[]>(`${AppConstants.API_URL}/Lookup/MagicItemRarityTypes`);
  }

  getMagicItemChargesresetType() {
    return this.http.get<ApiLookupMagicItemChargesResetType[]>(
      `${AppConstants.API_URL}/Lookup/MagicItemResetChargesTypes`
    );
  }

  getRaces() {
    return this.http.get<ApiLookupRace[]>(`${AppConstants.API_URL}/Lookup/Races`);
  }
}
